import React, {useContext, useState, useCallback} from 'react';
import IaImage from '../../../../assets/images/squadia.png';
import './styles.scss';
import { storage } from 'utils';
import LoaderButton from './loader';
import { PulseLoader } from 'react-spinners'
import { AuthContext } from 'contexts/appProvider';
import api from 'services/api/api';
import Swal from 'sweetalert2';
import axios from 'axios';
import LoaderCirculo from '../../../../assets/icons/circulo_loader.webp'


function AlertAi( {alertAiModal, setAlertAiModal, currentReport}) {
  const { setActiveIA, userData } = useContext(AuthContext);
  const [assistantId, setAssistantId] = useState(null)

  const [filtredAssistants, setFiltredAssistants] = useState([])

  const [words, setWords] = useState("");
  const [isAwaitResponseMessage, setIsAwaitResponseMessage] = useState(false)

  const [restoreLoading, setRestoreLoading] = useState(false)

  const [messages, setMessages] = useState([]);

  const [isLoading , setIsLoading] = useState(false)
  const getUrl = window.location.href

  const shouldShowModal = () => {
    const lastDismissTime = storage.get('@lastDismissTime');
    const currentTime = new Date().getTime();
    
    if (!lastDismissTime) return true; 
    
    const sixHours = 6 * 60 * 60 * 1000; // 6h em mili

    return currentTime - lastDismissTime > sixHours;
  };
  

  const closeAlert = () => {
    const currentTime = new Date().getTime();
    storage.set('@lastDismissTime', currentTime);
    setAlertAiModal(false);
  };
  
  
  async function sendFileToAssistant(id_assistant, id_dataset, id_dataset_workspace) {
    try {
        setIsLoading(true)
        const body = {
            id_assistant,
            datasetId: id_dataset,
            datasetWorkspaceId: id_dataset_workspace
        }

        const res = await api.post('/sendFileToAssistant', body)
        
        if(res.data.status == false) {
            setIsLoading(false)

            Swal.fire(
                "Oops, erro!",
                "Não foi possível, tente novamente!",
                "error"
            );
        }

    } catch (error) {
        console.error(error)
    }finally{
        setIsLoading(false)
    }
}


  async function switchAssistant(id_assistant) {
    try {
        setIsLoading(true);
        setAssistantId(id_assistant);
        setMessages([]);
        
        const res = await axios.get(`https://hooks.squadbi.app/chat/findChatActive/${Number(userData.id_usuario)}/${id_assistant}`);
        
        if (res.data && res.data.chat && res.data.chat.length > 0) {

            const oldMessage = res.data.chat;  

            let skipItem = false;
            const filteredMessages = [];

            for (let currentId = 0; currentId < oldMessage.length; currentId++) {
                const currentItem = oldMessage[currentId];
            
                if (skipItem) {
                    skipItem = false;
                    continue;
                }
            
                if (
                    currentItem.role === 'user' &&
                    (
                        currentItem.content.includes('A querie ao ser executada retornou o seguinte erro') ||
                        currentItem.content.includes('A querie ao ser executada não retornou nenhum registro')
                    )
                ) {
                    skipItem = true;
                    continue;
                }
            
                if (currentItem.role !== 'user') {
                    if (
                        oldMessage[currentId + 1] &&
                        oldMessage[currentId + 1].role === 'user' &&
                        (
                            oldMessage[currentId + 1].content.includes('A querie ao ser executada retornou o seguinte erro') ||
                            oldMessage[currentId + 1].content.includes('A querie ao ser executada não retornou nenhum registro')
                        )
                    ) {
                        if (
                            oldMessage[currentId + 3] &&
                            oldMessage[currentId + 3].role === 'user' &&
                            (
                                oldMessage[currentId + 3].content.includes('A querie ao ser executada retornou o seguinte erro') ||
                                oldMessage[currentId + 3].content.includes('A querie ao ser executada não retornou nenhum registro')
                            )
                        ) {
                            if (
                                oldMessage[currentId + 5] &&
                                oldMessage[currentId + 5].role === 'user' &&
                                (
                                    oldMessage[currentId + 5].content.includes('A querie ao ser executada retornou o seguinte erro') ||
                                    oldMessage[currentId + 5].content.includes('A querie ao ser executada não retornou nenhum registro')
                                )
                            ) {
                                filteredMessages.push({
                                    message: oldMessage[currentId + 6].content,
                                    isBot: oldMessage[currentId + 6].role !== 'user',
                                    activeAnimation: false,
                                    oldMessage: true,
                                });
                                continue;
                            } else {
                                filteredMessages.push({
                                    message: oldMessage[currentId + 4].content,
                                    isBot: oldMessage[currentId + 4].role !== 'user',
                                    activeAnimation: false,
                                    oldMessage: true,
                                });
                                continue;
                            }
                        } else {
                            filteredMessages.push({
                                message: oldMessage[currentId + 2].content,
                                isBot: oldMessage[currentId + 2].role !== 'user',
                                activeAnimation: false,
                                oldMessage: true,
                            });
                        }
                        continue;
                    } else {
                        filteredMessages.push({
                            message: currentItem.content,
                            isBot: currentItem.role !== 'user',
                            activeAnimation: false,
                            oldMessage: true,
                        });
                        continue;
                    }
                } else {
                    filteredMessages.push({
                        message: currentItem.content,
                        isBot: currentItem.role !== 'user',
                        activeAnimation: false,
                        oldMessage: true,
                    });
                }
            }

            setMessages(filteredMessages);
        } else {
            setMessages([]);
        }
        
        setIsLoading(false);
    } catch (error) {
        console.error(error);
    }
}  

  const getAssistants = useCallback(async (dataset) => {
    try {
        const res = await api.post('/assistants')
        if(res.data && res.data.status && res.data.assistants && res.data.assistants.length > 0) {
            
            const allAssistants = res.data.assistants
        
            const assistantsFiltred = allAssistants.filter(assistant => {

                let userAccess = false;
                let isAdmin = false;

                if (assistant.usuarios) {
                    assistant.usuarios.forEach(usuario => {
                        if (usuario.id == userData.id_usuario) {
                            userAccess = true;
                            if (usuario.admin == "1") {
                                isAdmin = true;
                            }
                        }
                    });
                }

                if (!userAccess && assistant.grupos) {
                    assistant.grupos.forEach(grupo => {
                        if (grupo.id == userData.grupoUsuario) {
                            userAccess = true;
                        }
                    });
                }


                if (userAccess) {
                    if (isAdmin) {
                        assistant.isAdmin = true;
                    }
                    return true;
                }

                return false;
            });

            if(dataset) {
                const findDataset = assistantsFiltred.find((item) => item.dataset === dataset.datasetId)
                if(findDataset) {
                    switchAssistant(findDataset.id_chatgpt)
                }
            }

            setFiltredAssistants(assistantsFiltred);

        }
    } catch (error) {
        
    }
}, [])

  async function requestReportsInfo(name) {
    try {
        const response = await api.post('/reportsInfo-v2', {
            "nome": name
        })
        return response.data
    } catch (error) {
        return error
    }
}

const getDatasetReport = useCallback(async (groupId, reportId) => {
  try {
      const token = await requestReportsInfo('SquadAI')
      const config = { headers: { 'Authorization': 'Bearer ' + token.token } };
      const res = await axios.get(`https://api.powerbi.com/v1.0/myorg/groups/${groupId}/reports/${reportId}`, config)
      const report = res.data
      return {datasetId: report.datasetId, datasetWorkspaceId: report.datasetWorkspaceId}
  } catch (error) {
      return
  }
}, [])


  const getAll = useCallback(async () => {
    try {
        setIsLoading(true) 
        if(getUrl.includes('/viewReport')) {
            const dataset = await getDatasetReport(currentReport.embedded_workspace, currentReport.embedded_relatorio)
            await getAssistants(dataset)
        } else {
            await getAssistants()
        }
    } catch (error) {
        console.error(error);
        return error;
    }finally{
        setIsLoading(false)
    }
}, [])

  const createAssistantReport = async () => {
    try {
      setIsLoading(true)

      const body =  {
          nome: `Assistente - ${currentReport.nome}`,
          dataset: currentReport.embedded_dataset, 
          workspace: currentReport.embedded_workspace,
          usuarios: [
            {
              id: userData.id_usuario,
              admin: true
            }
          ],
          grupos: []
      }  

      const res = await api.post('/createAssistant', body)

        if(res.data && res.data.assistant_id) {
          await sendFileToAssistant(res.data.assistant_id, currentReport.embedded_dataset, currentReport.embedded_workspace)
          await getAll()
          setIsLoading(false)
          setActiveIA(true)
          setAlertAiModal(false)
        } else {
            setIsLoading(false)
            Swal.fire(
                "Oops, erro!",
                "Não foi possível, tente novamente!",
                "error"
            );

        }
    } catch (error) {
        setIsLoading(false)
        return error;
    }
}

  return (
    <>
      {shouldShowModal() && alertAiModal && (
      <div className="alert">
      <div className="alert-content">
        <div className='separator-ai'>
        <img src={IaImage} alt="Ícone do modal" className="modal-image" />
        </div>
        <div className='content-body'>
            <h4 className='title-ai'>Turbine a produtividade com a AI</h4>
            <div style={{textAlign: 'start'}}>
              <p style={{letterSpacing: '-0.5px'}}>
              O Squad AI ajuda na tomada de decisões, permitindo perguntas, resumos e respostas ágeis. Experimente!
              </p>
            </div>
            <div className="modal-buttons">
            <button className="btn btn-secondary-ai" onClick={closeAlert} disabled={isLoading}>Dispensar</button>
            <button className={`btn btn-primary-ai ${isLoading ? 'loading' : ''}`} onClick={createAssistantReport}>
              {isLoading ? <img src={LoaderCirculo} style={{width: '26px', height: '26px'}} /> : 'Teste sem custo'}
              </button>
            </div>
        </div>
      </div>
    </div>)}
    </>
  );
}

export default AlertAi;