import React, { useEffect, useContext, useState } from 'react';
import './styles.scss';
import logoSquad from '../../../../../src/assets/images/logo_expired.png';

export default function ContractDisabled({ onRender, onClose}) {

  return (
      <div className="aimodal-modal">
      <div className="aimodal-modal-content">
          <div className='close'>
          <button className="close-button-btn"   
          style={{
            position: 'relative',
            bottom: '21px',
            left: '13px',
            border: 'none',
            background: 'transparent'
          }}
          onClick={() => onClose()} aria-label="Fechar">
              &times;
            </button>
          </div>
        <div className='header'>
          <span className='title-header'><b>Aviso Importante !</b></span>
        </div>
        <div className='image-div-contract'>
          <img src={logoSquad} alt="Tour" className="aimodal-image" />
        </div>
        <div className='text'>
          <div className='sub-title'> 
            <span className='welcome'> <b>Entre em contato com os administradores da sua organização</b> </span>
          </div>
          <p>
          Se faz necessário entrar em contato com a SquadBI pelo telefone <b>(85) 99633-4242 </b>ou pelo e-mail <b>contato@squadbi.tech</b> para mais informações. Agradecemos pela sua atenção.
          </p>
        </div>
        <div className='button-container'>
        </div>
      </div>
    </div>
        )
}
