import React from 'react';
import Swal from 'sweetalert2';
import api from 'services/api/api';
import storage from './storage';

const verificarContratoInadimplente = async () => {
  try {
    const user = storage.get('@tijuca-usuario');

    if (!user || !user.id_empresa) {
      throw new Error('Usuário ou ID da empresa não encontrado');
    }

    const isAdmin = user.adm === 1;
    const idEmpresa = user.id_empresa;

    console.log('isAdmin', isAdmin)

    if (storage.get('@inadimplente') == 1) {
      return  mostrarAlertaContratoAtrasado(true, contractEnterprise);
     }

    const formData = new FormData();
    formData.append('id_empresa', idEmpresa);

    const response = await api.post('/contract', formData);


    const { contractActive, contract } = response.data;

    if (!contractActive || !contractActive[0]) {
      console.log('Nenhum contrato ativo encontrado');
    }


    const empresa = contractActive[0] ;
    const contractEnterprise = contract.find(
      (item) => item.id_contrato == empresa.id_contrato
    );

    if(contractEnterprise !== 1){
      storage.set('@inadimplente', 0)
    }

    if (contractEnterprise.inadimplente == 1) {
      mostrarAlertaContratoAtrasado(true, contractEnterprise);
      storage.set('@inadimplente', 1)
    }


  } catch (error) {
    console.error('Erro ao verificar contrato:', error.message);
  }
};

const mostrarAlertaContratoAtrasado = (isAdmin, contractEnterprise) => {
  if (true) {
    Swal.fire({
      title: 'Aviso Importante',
      text: 'Se faz necessário entrar em contato com a SquadBI pelo telefone (85) 99633-4242 ou pelo e-mail contato@squadbi.tech para mais informações. Agradecemos pela sua atenção.',
      icon: 'info',
      confirmButtonText: 'Entendi',
    });
  }
};

export default verificarContratoInadimplente;
