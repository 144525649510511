import React, { useState, createContext, useEffect } from "react";
import useStorage  from '../hooks/useStorage';
import Helmet from 'react-helmet';
import mainLogo from '../assets/images/logo.svg';
import secondaryLogo from '../assets/images/logo-mini.svg';
import logoLogin  from "../assets/images/logo.png";
import api from '../services/api/api';
import { useHistory, useLocation } from 'react-router-dom'
import Swal from "sweetalert2";
import { models } from 'powerbi-client';
import { storage } from "utils";
import { deslogar } from 'services/api/autenticacao';
import ShowAlert from "app/components/alerts";
import ModalSquadAI from "app/components/modalSquadIA";
import ContractExpired from "app/components/alerts/contractExpired";
import ContractDisabled from "app/components/alerts/contractDisabled";
import icepesImg from '../assets/images/auth/icepes.png'
import axios from "axios";

export const AuthContext = createContext();

export const AppProvider = ({ children }) => {

    const [userData, setUserData] = useStorage("@tijuca-usuario");
    const [codEmpresa, setCodEmpresa] = useStorage("@codEmpresa");
    const [msalInstance, setMsalInstance] = useStorage("@msal-instance");
    const [dataAppearance, setDataAppearance] = useStorage("dataAppearance");
    const [colorDefault, setColorDefault] = useStorage("colorDefault");
    const [colorFontSideBar, setColorFontSideBar] = useStorage("colorFontSidebarDefault");
    const [reportsAccess, setReportsAccess] = useStorage("@reportsAccess");
    const [nameCompany, setNameCompany] = useStorage("nameCompany");
    const [openMenu, setOpenMenu] = useStorage("@openMenu");
    const [idCurrentReport, setIdCurrentReport] = useStorage("@idCurrentReport");
    const [ loginKeycloak ] = useStorage("@loginKeycloak");
    const [openBackdropRequest, setOpenBackdropRequest] = useState(false);
    const [reloadReqEmbedded, setReloadReqEmbedded] = useState(false);
    const [hasFilter, setHasFilter] = useState(false);
    const [rlsFunction, setRlsFunction] = useState(null)
    const [rlsFunctionId, setRlsFunctionId] = useState(null)
    const [showAlertsId, setShowAlertsId] = useState(0);
    const [activeIa, setActiveIA] = useState(false)
    const [expiredContract, setExpiredContract] = useState(false)
    const [contractDisabled, setContractDisabled] = useState(false)
    const [reloadRefreshDate, setReloadRefreshDate] = useState(false)
    const [hasPowerAutomate, setHasPowerAutomate] = useStorage(false);
    const [loadingPowerAutomate, setLoadingPowerAutomate] = useState(false);

    const navigate = useHistory()
    const location = useLocation();

    const path = window.location.href 
    const getURL = window.location.host

    useEffect(() => {
        setRlsFunction(null)
        setRlsFunctionId(null)
    },[getURL, path])

    useEffect(() => {
        if(openMenu){
        document.body.classList.add('sidebar-icon-only');
        }else{
            document.body.classList.remove('sidebar-icon-only')
        }
        
        if(userData){
            renderDefaultAttributes(dataAppearance, false);
        }

        return () => onRefreshUserPermissionsAccess();
        
    },[document.body.classList.contains('sidebar-icon-only'), window.location.href, location]);

    const renderDefaultAttributes = async (data, storageData = true) => {
        setDataAppearance(data);
    
        const { 
            logo,
            logo_height,
            logo_small,
            logo_small_height,
            favicon,
            background_sidebar,
            nome_navegador,
            logo_login
        } = data;
    
        const favicon_element = document.getElementById("favicon");
        const logoNavbar_element = document.getElementById("logoNavbar");
        const miniLogoNavbar_element = document.getElementById("miniLogoNavbar");
        const loginImage = document.getElementById("logo_login");
    
        if (loginImage) {
            loginImage.setAttribute("src", logo_login);
        }

    
    
        if (!!logo) {
            logoNavbar_element.setAttribute("src", logo);
            logoNavbar_element.style.height = `${logo_height > 140 ? 140 : logo_height}px`;
        }
    
        if (!!logo_small) {
            miniLogoNavbar_element.setAttribute("src", logo_small);
            miniLogoNavbar_element.style.height = `${logo_small_height || 40}px`;
        }
    
        if (!!favicon) {
            favicon_element.setAttribute("href", favicon);
        }

        if(!nome_navegador){
            document.title = "SquadBI - SAAS"
        }else{
            document.title = nome_navegador;
        }
    
        
    
        if (!storageData && !!background_sidebar) {
            setColorDefault(background_sidebar);
        }

        // icepes personalização
        if(window.location.host.includes('icepes')){
            logoNavbar_element.setAttribute("src", icepesImg);
            miniLogoNavbar_element.setAttribute("src", icepesImg);
            miniLogoNavbar_element.style.height = '40px';
            miniLogoNavbar_element.style.marginTop = '6px';
            setColorDefault('#222');
            logoNavbar_element.style.height = `${logo_height > 140 ? 140 : 70}px`;
            logoNavbar_element.style.marginTop = '64px'
        }
        
        
    };

    const changeReloadRefreshDate = () => {
        setReloadRefreshDate(!reloadRefreshDate)
    }
    
    const findDataAparence = async () => {
        try {
            const res = await api.post('appearance');
          
            const { 
                logo,
                logo_height,
                logo_small,
                logo_small_height,
                favicon,
                background_sidebar,
                nome_navegador,
                logo_login,
                estrutura_workspaces 
            } = res.data.configuracoes;
    
            const updatedData = {
                logo: logo,
                logo_height: logo_height,
                logo_small: logo_small,
                logo_small_height: logo_small_height,
                favicon: favicon,
                background_sidebar: background_sidebar ? background_sidebar : '#003c7c',
                logo_login : logo_login,
                nome_navegador: nome_navegador,
                estrutura_workspaces: estrutura_workspaces
            };

            renderDefaultAttributes(updatedData, false);
    
            setDataAppearance(updatedData);
    
        } catch (error) {
            console.log(error.message);
        }
        finally{
            window.location.href = '/'
        }
    };
    

    const onToggleMenu = (statusMenu) => {
        if(statusMenu){
            document.body.classList.add('sidebar-icon-only');
            setOpenMenu(statusMenu);
          }else{
              document.body.classList.remove('sidebar-icon-only')
            setOpenMenu(statusMenu);
          }
    }

    async function hasAssistantAi() {
        try {
            const res = await api.post('/hasAssistant');
            const hasAssistant = res.data.has_assistant;
            const acessoIa = res.data.teste_ia
    
            storage.set('@exibirAssistente', hasAssistant);
            storage.set('@acesso-ia', acessoIa)   

        } catch (error) {
            console.error("Erro ao verificar assistente:", error);
            storage.set('@exibirAssistente', false);
            storage.set('@acesso-ia', false)   
            return false; 
        }
    }
    
    const onRefreshUserPermissionsAccess = async() => {
        try {

            generateTokenGeneral()
            hasRollback()
            hasAssistantAi()
            displayAlert();
            
            const response = await api.post('/updatePermissions', {
                isAdmin: userData.login === "admin"
            })
            
            const {user, userRemove} = response.data;
            
            if(user) {
                if(user.usuario_atualizado) {
                    const updateDateUser = "@update_user";
                    const storedDate = storage.get(updateDateUser);
                    
                    if(!storedDate || storedDate !== user.usuario_atualizado) {
                        clearGeneralToken()
                        generateTokenGeneral()
                        storage.set(updateDateUser, user.usuario_atualizado);
                    }
                }
                
                setReportsAccess(user.relatorios);
                setUserData(user)
            } else if(userRemove) {
                clearGeneralToken()
                deslogar()
                window.location.href = '/'
            }
     
        } catch(error) {
            console.error(error);
        }
    }

    async function verifyauthKeycloak(){

        const options = {
            access_token:storage.get('@tokenKeycloak'),
            refresh_token: storage.get('@refreshTokenKeycloak'),
            validateTokenKeycloak:1
        };
        
       await api.post('/authenticateKeycloack', options)
        .then(({data:{ access_token }}) => {
            if(access_token){
                storage.set('@tokenKeycloak',access_token)
            }
        })
          .catch(error => {
            if(error.response){
                Swal.fire({
                    title: error.response.status === 401 ? "Sua sessao expirou, faca login novamente!" : error.message,
                    icon: error.response.status === 401 ? 'warning' : 'error',
                    showConfirmButton: false,
                    allowEnterKey: false,
                    allowOutsideClick: false,
                    timer: 1500,
                    didClose: () => {
                        storage.clear()
                        navigate.push('/login');
                    }
                });
            }

          });
    }

    async function generatePdfEmbed(){
        try {
            await window.report.print();
        }
        catch (errors) {
            console.log(errors);
        }        
    }


    async function hasRollback() {
        try {
            const res = await api.post('/reports/rollback/has_rollback');
            // console.log('valor', res.data)
            const hasRollback = res.data.rollback_active;
    
            storage.set('@rollback_ativo', hasRollback);            
        } catch (error) {
            console.error("Erro", error);
            storage.set('@rollback_ativo', 0);
            return false; 
        }
    }

    async function fullScreenEmbed(){
        try {
            await window.report.fullscreen();
        }
        catch (errors) {
            console.log(errors);
        }        
    }
    
    async function handleRemoveFilters() {
        try {
            const activePage = await window.report.getActivePage();
            const promises = [reloadEmbedForFilters(activePage), clearStartBookmark()]
            await Promise.all(promises)
            
            reloadRequestEmbedded()

        } catch (error) {
            console.error(error)
        }
    }

    async function reloadEmbedForFilters(value){
        try {
            await window.report.reload();
            window.report.on("loaded", async function () {
                await value.setActive()
            });
        }
        catch (errors) {
            console.log(errors);
        }        
    }

    async function clearStartBookmark() {
        try {
            const newSettings = {
                bookmark: {state: undefined},
            };
            await window.report.updateSettings(newSettings);
        }
        catch (error) {
            console.log(error);
        }
    }

    async function reloadAndRefreshEmbed() {
        try {
            await window.report.refresh();
            await window.report.reload()
        }
        catch (errors) {
            console.log(errors);
        }
    }

    async function reloadEmbed(){
        try {
            await window.report.reload();
        }
        catch (errors) {
            console.log(errors);
        }        
    }

    async function captureBookmarksEmbed(){
        try {
            const capturedBookmark = await window.report.bookmarksManager.capture({allPages: true, personalizeVisuals: true});
            return capturedBookmark.state
        }
        catch (errors) {
            console.log(errors);
        }        
    }

    async function apllybookmarkState(state){
        try {
            await window.report.bookmarksManager.applyState(state);            
        }
        catch (errors) {
            console.log(errors);
        }        
    }

    async function downloadPBIX(fileUrl,nameReport){        
        try {
            window.open(fileUrl)
        } catch (error) {
            console.error('Erro ao fazer o download do arquivo .pbix:', error);
        }    
    }


    function handleOpenBackdropRequest(isVisible){
        try {
            setOpenBackdropRequest(isVisible)
        } catch (error) {
            console.log(error.message)
        }
    }

    function reloadRequestEmbedded() {
        setReloadReqEmbedded(!reloadReqEmbedded)
    }
    
    function applyVerifyRls(rls_function, id) {
        setRlsFunction(rls_function)
        setRlsFunctionId(id)
        setReloadReqEmbedded(!reloadReqEmbedded)
    }

    function findFilters(idReport) {
        const userHistory = storage.get('@user_history');
        let bookmarkState;

        if (idReport === '0e292097-2339-475c-8227-1b71e4ab4314') {
            bookmarkState = undefined;
        } else {
            if (userHistory && userHistory.filter(item => item.embedded_relatorio === idReport).length > 0) {
                bookmarkState = userHistory.find(item => item.embedded_relatorio === idReport).last_bookmark_state;
            } else {
                bookmarkState = undefined;
            }
        }
        if(bookmarkState) {
            setHasFilter(true)
        } else {
            setHasFilter(false)
        }
    }
    
    async function displayAlert() {
        try {
            const response = await api.post("/displayAlerts")

            if (response.status === 200) {
                if (response.data) {
                    if (response.data.value) {

                        if (response.data.value.length === 0) {
                            return
                        } else if (response.data.value.length > 0){
                            setShowAlertsId(response.data.value[0])
                        }
                    }
                }
            }

        } catch (error) {
            console.error(error)
        }
    }
    

    function handleCloseDisplayAlert() {
        setShowAlertsId(0)
        storage.set('@exibirAssistente', false)
    }

    async function handleCreateDisableAlertForUser(id) {
        try {
            const body = {"id_display_alert": id};
            await api.post('/generateLogAlert', body)
        } catch (error) {
            console.error(error)
        }
    }

    ////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////

    // EMBEDDED TOKEN - EMBEDDED TOKEN - EMBEDDED TOKEN - EMBEDDED TOKEN
    // ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓

    ////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////

    async function generateTokenGeneral() {
        try {

            const isTokenExpired = (expirationDate) => {
                if (!expirationDate) return true;
                return new Date(expirationDate) <= new Date();
            };
    
            const clearAllStorages = () => {

                storage.remove("@squadbi_embedded_token");
                
                let batchNumber = 1;
                let storageTag = `@squadbi_embedded_token_batch_${batchNumber}`;
                
                while (storage.get(storageTag)) {
                    storage.remove(storageTag);
                    batchNumber++;
                    storageTag = `@squadbi_embedded_token_batch_${batchNumber}`;
                }
            };
    
            const defaultToken = storage.get("@squadbi_embedded_token");
            if (defaultToken) {

                if (!isTokenExpired(defaultToken.expiration)) {
                    return;
                }

            } else {

                let batchNumber = 1;
                let hasExpiredToken = false;
                let storageTag = `@squadbi_embedded_token_batch_${batchNumber}`;
                let batchToken = storage.get(storageTag);
                
                while (batchToken) {
                    if (isTokenExpired(batchToken.expiration)) {
                        hasExpiredToken = true;
                        break;
                    }
                    batchNumber++;
                    storageTag = `@squadbi_embedded_token_batch_${batchNumber}`;
                    batchToken = storage.get(storageTag);
                }
    
                if (!hasExpiredToken && batchNumber > 1) {
                    return;
                }
            }
    
            clearAllStorages();
    
            const res = await api.post('/globalToken');
            
            if(res.data.status) {
                const values = res.data.values;
    
                if(values.length > 1) {
                    values.forEach((value, index) => {
                        const batchNumber = index + 1;
                        const storageTag = `@squadbi_embedded_token_batch_${batchNumber}`;
                        storage.set(storageTag, value);
                    });
                } else {
                    const storageTag = "@squadbi_embedded_token";
                    storage.set(storageTag, res.data.values[0]);
                }
            }
        } catch(error) {
            return error;
        }
    }

    async function getGeneralToken(reportId) {
        try {

            const isTokenExpired = (expirationDate) => {
                if (!expirationDate) return true;
                return new Date(expirationDate) <= new Date();
            };
    
            const clearAllStorages = () => {
                storage.remove("@squadbi_embedded_token");
                
                let batchNumber = 1;
                let storageTag = `@squadbi_embedded_token_batch_${batchNumber}`;
                
                while (storage.get(storageTag)) {
                    storage.remove(storageTag);
                    batchNumber++;
                    storageTag = `@squadbi_embedded_token_batch_${batchNumber}`;
                }
            };
    
            const defaultToken = storage.get("@squadbi_embedded_token");
            if (defaultToken && !isTokenExpired(defaultToken.expiration)) {
                const matchedReport = defaultToken.reports.find(report => report.embedded_id === reportId);
                if (matchedReport) {
                    return {
                        token: defaultToken.token,
                        rlsBlock: matchedReport.rlsBlock,
                        expiration: defaultToken.expiration,
                        paginatedToken: defaultToken.paginatedToken
                    };
                }
            }
    
            let batchNumber = 1;
            let storageTag = `@squadbi_embedded_token_batch_${batchNumber}`;
            let batchToken = storage.get(storageTag);
            
            while (batchToken) {
                if (!isTokenExpired(batchToken.expiration)) {
                    const matchedReport = batchToken.reports.find(report => report.embedded_id === reportId);
                    if (matchedReport) {
                        return {
                            token: batchToken.token,
                            rlsBlock: matchedReport.rlsBlock,
                            expiration: batchToken.expiration,
                            paginatedToken: batchToken.paginatedToken
                        };
                    }
                }
                batchNumber++;
                storageTag = `@squadbi_embedded_token_batch_${batchNumber}`;
                batchToken = storage.get(storageTag);
            }
    
            clearAllStorages();
            await generateTokenGeneral();
    
            const newDefaultToken = storage.get("@squadbi_embedded_token");
            if (newDefaultToken) {
                const matchedReport = newDefaultToken.reports.find(report => report.embedded_id === reportId);
                if (matchedReport) {
                    return {
                        token: newDefaultToken.token,
                        rlsBlock: matchedReport.rlsBlock,
                        expiration: newDefaultToken.expiration,
                        paginatedToken: newDefaultToken.paginatedToken
                    };
                }
            }
    
            batchNumber = 1;
            storageTag = `@squadbi_embedded_token_batch_${batchNumber}`;
            batchToken = storage.get(storageTag);
            
            while (batchToken) {
                const matchedReport = batchToken.reports.find(report => report.embedded_id === reportId);
                if (matchedReport) {
                    return {
                        token: batchToken.token,
                        rlsBlock: matchedReport.rlsBlock,
                        expiration: batchToken.expiration,
                        paginatedToken: batchToken.paginatedToken
                    };
                }
                batchNumber++;
                storageTag = `@squadbi_embedded_token_batch_${batchNumber}`;
                batchToken = storage.get(storageTag);
            }
    
            throw new Error(`Token not found for reportId: ${reportId}`);
        } catch (error) {
            throw error;
        }
    }

    function clearGeneralToken() {
        const tokensToRemove = ["@squadbi_embedded_token"];
        let batchNumber = 1;
        let storageTag = `@squadbi_embedded_token_batch_${batchNumber}`;
        
        while (storage.get(storageTag)) {
            tokensToRemove.push(storageTag);
            batchNumber++;
            storageTag = `@squadbi_embedded_token_batch_${batchNumber}`;
        }
        
        tokensToRemove.forEach(key => storage.remove(key));
    } 

    ////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////

    // POWER AUTOMATE - POWER AUTOMATE - POWER AUTOMATE - POWER AUTOMATE
    // ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓

    ////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////

    async function getToken() {
        try {
            const response = await api.post('/reportsInfo-v2', {
                "nome": 'token'
            })
            return response.data
        } catch (error) {
            return error
        }
    }

    async function sendMailAutomate() {
        try {
            
            setLoadingPowerAutomate(true);
            const pages = await window.report.getPages();
            let page = pages.filter(function (page) {
                return page.isActive
            })[0];
      
            const visuals = await page.getVisuals();
            let visual = visuals.filter(function (visual) {
                return visual.name === "46c7ebc87a345382f9b9";
            })[0];
      
            const result = await visual.exportData(models.ExportDataType.Summarized);
            const htmlContent = result.data;
      
            const getDataHtml = (html) => {
                const cleanHtml = html
                    .replace(/^cartaMargem\s*"/, '')    
                    .replace(/"\s*$/, '')
                    .trim();                        
                
                const funcionarioRegex = /que\s+<strong>\s*(.*?)\s*<\/strong>\s+matrícula/;
                const assinaturaRegex = /<strong>[^>]*?___+<br>(.*?)<br>/;
                const bancoRegex = /CESAMA e o\s+<strong>\s*(.*?)\s*<\/strong>/;
                const tipoRegex = /\*\s*(MARGEM PERMITIDA[^*]*)\*/;
            
                const funcionario = (cleanHtml.match(funcionarioRegex) || [])[1] || '';
                const assinatura = (cleanHtml.match(assinaturaRegex) || [])[1] || '';
                const banco = (cleanHtml.match(bancoRegex) || [])[1] || '';
                const tipo = (cleanHtml.match(tipoRegex) || [])[1] || '';
            
                return {
                    html: cleanHtml,
                    funcionario: funcionario.trim(),
                    assinatura: assinatura.trim(),
                    banco: banco.trim(),
                    tipo: tipo.trim()
                };
            };
      
            const htmlData = getDataHtml(htmlContent);
  
            const email = await getMailAutomate(htmlData.assinatura)
            
            const body = {
                email: email,
                html: htmlData.html,
                assinatura: htmlData.assinatura,
            }
                        
            const response = await api.post('/startPowerAutomate', body)

            if(response.data.status) {
                Swal.fire({
                    toast: true,
                    position: "top-end",
                    icon: "success",
                    title: "Power Automate iniciado com sucesso!",
                    showConfirmButton: false,
                    timer: 6500
                });
            } else {
                Swal.fire({
                    toast: true,
                    position: "top-end",
                    icon: "success",
                    title: "Houve uma falha na geração do Power Automate!",
                    showConfirmButton: false,
                    timer: 6500
                });
            }

            setLoadingPowerAutomate(false);
        }
        catch (error) {
            setLoadingPowerAutomate(false);
            console.log(error);
        }
    }
  
    async function getMailAutomate(nome) {
  
        let token = await getToken();
        const config = { headers: { 'Authorization': 'Bearer ' + token.token } };
  
        const query = `
          EVALUATE
          CALCULATETABLE(
            SELECTCOLUMNS(
              'Assinatura',
              "email", 'Assinatura'[email]
            ),
            'Assinatura'[nome] = "${nome}"
          )
        `;
      
        const body = {
          "queries": [
            {
              "query": query,
            }
          ]
        };
      
        try {
          const response = await axios.post(`https://api.powerbi.com/v1.0/myorg/groups/1d00d379-72c9-40cd-9c17-375eed6f8094/datasets/7decf7ca-8c8a-4bb9-9d96-7a44d1932161/executeQueries`, body, config);
          
          return response.data.results[0].tables[0].rows[0]["[email]"] || null
          
        } catch (error) {
          console.error('Erro ao buscar email:', error);
          return null;
        }
    }

    const valuesProvider = {
        userData,
        idCurrentReport,
        openMenu,
        openBackdropRequest,
        colorDefault,
        colorFontSideBar,
        nameCompany,
        dataAppearance:dataAppearance,
        reportsAccess,
        msalInstance,
        setNameCompany,
        setColorFontSideBar,
        setColorDefault,
        setReportsAccess,
        setDataAppearance,
        setMsalInstance,
        setIdCurrentReport,
        renderDefaultAttributes,
        onToggleMenu,
        onRefreshUserPermissionsAccess,
        generatePdfEmbed,
        fullScreenEmbed,
        reloadEmbed,
        captureBookmarksEmbed,
        apllybookmarkState,
        downloadPBIX,
        handleOpenBackdropRequest,
        findDataAparence,
        handleRemoveFilters,
        reloadAndRefreshEmbed,
        reloadReqEmbedded,
        reloadRequestEmbedded,
        setHasFilter,
        hasFilter,
        findFilters,
        rlsFunction,
        applyVerifyRls,
        rlsFunctionId,
        generateTokenGeneral,
        reloadRefreshDate,
        changeReloadRefreshDate,
        setActiveIA,
        activeIa,
        showAlertsId,
        expiredContract, 
        setExpiredContract,
        handleCreateDisableAlertForUser,
        setContractDisabled,
        getGeneralToken,
        clearGeneralToken,
        setHasPowerAutomate,
        hasPowerAutomate,
        sendMailAutomate,
        loadingPowerAutomate
    }

    return (
        <AuthContext.Provider value={{...valuesProvider}}>
            <Helmet 
                title={
                    (dataAppearance && dataAppearance.nome_navegador && dataAppearance.nome_navegador) ?
                        dataAppearance.nome_navegador :
                        storage.get('@codEmpresa') === 'gold' ? 'Golden Cloud' :
                        storage.get('@codEmpresa') === 'idt' ? 'IDT BI' :
                        'SquadBI - SAAS'
                }
            />
            <ShowAlert
                showAlertsId={showAlertsId}
                onCloseAlert={() => handleCloseDisplayAlert()}
                onDisableAlert={value => handleCreateDisableAlertForUser(value)}
            />
                {activeIa && !window.location.href.includes('viewReport') && <ModalSquadAI />}
                {expiredContract && <ContractExpired />}
                {contractDisabled && <ContractDisabled  onClose={() => setContractDisabled(false)}/>}
                {children}
        </AuthContext.Provider> 
    );
};
export default AppProvider;
